import React, { useState } from 'react';
import './PriceSimulator.css';
import Navbar from './Navbar';

const packages = {
    realEstate: [
        {
            name: 'Basic Real Estate Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management'
            ],
            monthlyCharge: 2500,
            freelancerCost: 1200,
            profitMargin: 1300
        },
        {
            name: 'Advanced Real Estate Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management',
                'Property Data Analytics and Market Research with AI'
            ],
            monthlyCharge: 4500,
            freelancerCost: 2000,
            profitMargin: 2500
        },
        {
            name: 'Premium Real Estate Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management',
                'Property Data Analytics and Market Research with AI',
                'Virtual Tour and 3D Modeling with AI'
            ],
            monthlyCharge: 7000,
            freelancerCost: 3000,
            profitMargin: 4000
        }
    ],
    healthcare: [
        {
            name: 'Basic Healthcare Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management'
            ],
            monthlyCharge: 3000,
            freelancerCost: 1500,
            profitMargin: 1500
        },
        {
            name: 'Advanced Healthcare Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management',
                'Telemedicine Platform Setup with AI Integration'
            ],
            monthlyCharge: 5500,
            freelancerCost: 2500,
            profitMargin: 3000
        },
        {
            name: 'Premium Healthcare Package',
            services: [
                'SEO and Content Marketing',
                'Social Media Management',
                'Telemedicine Platform Setup with AI Integration',
                'AI-Powered Regulatory Compliance Monitoring'
            ],
            monthlyCharge: 8000,
            freelancerCost: 3500,
            profitMargin: 4500
        }
    ]
};

const PriceSimulator = () => {
    const [selectedCategory, setSelectedCategory] = useState('realEstate');
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedPackage(null);
    };

    const handlePackageChange = (event) => {
        const packageName = event.target.value;
        const packageDetails = packages[selectedCategory].find(pkg => pkg.name === packageName);
        setSelectedPackage(packageDetails);
    };

    return (
        <div>
            <Navbar />
        <div className="price-simulator">
            <h2>Price Simulator</h2>
            <div className="category-selector">
                <label>Select a Category:</label>
                <select value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="realEstate">Real Estate</option>
                    <option value="healthcare">Healthcare</option>
                </select>
            </div>
            <div className="package-selector">
                <label>Select a Package:</label>
                <select value={selectedPackage?.name || ''} onChange={handlePackageChange}>
                    <option value="" disabled>Select a package</option>
                    {packages[selectedCategory].map((pkg, index) => (
                        <option key={index} value={pkg.name}>{pkg.name}</option>
                    ))}
                </select>
            </div>
            {selectedPackage && (
                <div className="package-details">
                    <h3>{selectedPackage.name}</h3>
                    <ul>
                        {selectedPackage.services.map((service, index) => (
                            <li key={index}>{service}</li>
                        ))}
                    </ul>
                    <p>Monthly Charge: ${selectedPackage.monthlyCharge}</p>
                </div>
            )}
        </div>
        </div>
    );
};

export default PriceSimulator;
