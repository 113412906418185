import React from 'react';
import './Footer.css';
import Logo from "../img/logo.png"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <img src={Logo} alt="Logo" className="footer-logo" />
                </div>
                <div className="footer-column">
                    <h4>Policies</h4>
                    <ul>
                        <li>Work Policies</li>
                        <li>Money</li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Services</h4>
                    <ul>
                        <li>Web development and maintanance</li>
                        <li>App Development</li>
                        <li>Online services</li>
                        <li>Artificial intelligence integration</li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>About</h4>
                    <ul>
                      <a href='mailto:contact@romacol.com?subject=I%20Want%20Info%20About%3A%20'><li>Contact Us</li></a>
                    <li>Join Our Team</li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2024. All Rights Reserved Romacol.</p>
            </div>
        </footer>
    );
}

export default Footer;
