import React from 'react';
import './ServicesSection.css';

const ServicesSection = () => {
    return (
        <div className="services-section" id='services'>
            <h2 className="services-heading">
                Your business needs to take the next leap
            </h2>
            <p className="services-subheading">
                Our team of experts provide top-notch services like: 
            </p>
            <div className="service-cards">
                <div className="service-card purple-gradient">
                    <button className="service-button">Web development and maintanance</button>
                </div>
                <div className="service-card blue-gradient">
                    <button className="service-button">App Development</button>
                </div>

                <div className="service-card red-gradient">
                    <button className="service-button">Social Media Managment</button>
                </div>

                <div className="service-card green-gradient">
                    <button className="service-button">Online services</button>
                </div>

                <div className="service-card orange-gradient">
                    <button className="service-button">Artificial intelligence integration</button>
                </div>
            </div>
        </div>
    );
}

export default ServicesSection;
