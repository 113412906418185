import React from 'react';
import Navbar from './Navbar';
import ServicesSection from './ServicesSection';
import './Home.css';
import Footer from './Footer';

const Home = () => {
    return (
        <div className="home">
            <Navbar />
            <div className="hero-section">
                <div className="hero-content">
                    <h1>Boost your business with expert online solutions</h1>
                    <p>Elevate your business with our cutting edge online services.</p>
                    <button className="cta-button">Learn More</button>
                </div>
                <div className="hero-image">
                    <img style={{ height: "80%" }} src="https://images.pexels.com/photos/360438/pexels-photo-360438.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                </div>
            </div>
            <ServicesSection />
            <div className="service-section">
                <div className="service-section-content">
                    <div className="service-image-container">
                        <img style={{ height: "300px" }} src="            https://images.pexels.com/photos/6168/hands-woman-laptop-notebook.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2
" alt="Web Development" />
                    </div>
                    <div className="service-section-text">
                        <h2 className="service-heading">Web Development and Maintenance</h2>
                        <h3 className="service-subheading">Custom, scalable, and secure websites to grow your business.</h3>
                        <p className="service-description">
                            Our team builds responsive and high-performing websites tailored to your business needs. From e-commerce platforms to corporate websites, we ensure seamless user experience and robust security.
                        </p>
                        <p className="service-description">
                            Regular maintenance and updates keep your website running smoothly, with continuous improvements to enhance performance and user engagement.
                        </p>
                        <button className="read-more-button">Read more</button>
                    </div>
                </div>
            </div>

            <div className="service-section">
                <div className="service-section-content">
                    <div className="service-section-text">
                        <h2 className="service-heading">Social Media Management</h2>
                        <h3 className="service-subheading">Engage and grow your audience on social media.</h3>
                        <p className="service-description">
                            Our social media experts create and manage your social media campaigns, ensuring consistent brand messaging and engagement. From content creation to analytics, we handle all aspects of your social media presence.
                        </p>
                        <p className="service-description">
                            Increase brand awareness, drive traffic, and build a loyal community with our tailored social media strategies.
                        </p>
                        <button className="read-more-button">Read more</button>
                    </div>
                    <div className="service-image-container">
                        <img src="https://images.pexels.com/photos/248533/pexels-photo-248533.jpeg?auto=compress&cs=tinysrgb&w=800" alt="Social Media Management" className="service-image" />
                    </div>
                </div>
            </div>
            <div className="service-section">
                <div className="service-section-content">
                    <div className="service-image-container">
                        <img style={{ height: "300px" }} src="https://images.pexels.com/photos/8438957/pexels-photo-8438957.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="AI Integration" />
                    </div>
                    <div className="service-section-text">
                        <h2 className="service-heading">AI Integration</h2>
                        <h3 className="service-subheading">Harness the power of AI to transform your business.</h3>
                        <p className="service-description">
                            Integrate AI solutions to automate processes, gain insights, and improve decision-making. Our AI services include chatbots, machine learning models, and data analytics to boost efficiency and drive growth.
                        </p>
                        <p className="service-description">
                            Leverage AI to personalize customer experiences, optimize operations, and stay ahead of the competition.
                        </p>
                        <button className="read-more-button">Read more</button>
                    </div>
                </div>
            </div>
            <div className="service-section">
                <div className="service-section-content">
                    <div className="service-section-text">
                        <h2 className="service-heading">App Development</h2>
                        <h3 className="service-subheading">Innovative mobile solutions for your business.</h3>
                        <p className="service-description">
                            We create user-friendly mobile apps for iOS and Android platforms, providing your customers with seamless access to your services on the go. Our apps are designed for performance, security, and scalability.
                        </p>
                        <p className="service-description">
                            From concept to deployment, our team ensures your app aligns with your business goals and provides an intuitive user experience.
                        </p>
                        <button className="read-more-button">Read more</button>
                    </div>
                    <div className="service-image-container">
                        <img src="            https://images.pexels.com/photos/5081930/pexels-photo-5081930.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2
" alt="App Development" className="service-image" />
                    </div>
                </div>
            </div>
            

            <div className="service-section">
                <div className="service-section-content">
                    <div className="service-image-container">
                        <img style={{ height: "300px" }} src="https://images.pexels.com/photos/5083010/pexels-photo-5083010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Online Services" />
                    </div>
                    <div className="service-section-text">
                        <h2 className="service-heading">Online Marketing Services</h2>
                        <h3 className="service-subheading">Maximize your online presence and reach your target audience.</h3>
                        <p className="service-description">
                            Our online marketing services include SEO, PPC, email marketing, and more. We create data-driven strategies to increase your visibility, drive traffic, and generate leads.
                        </p>
                        <p className="service-description">
                            Stay ahead in the digital landscape with our comprehensive marketing solutions designed to deliver measurable results.
                        </p>
                        <button className="read-more-button">Read more</button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;
